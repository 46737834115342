<!-- On mets ici tout les textes à traduire qui sont dynamiques dans le reste du code (cad généré avec des variables) -->
{{ 'menu.Mise à jour' | translate }}
{{ 'menu.enquête' | translate }}
{{ 'menu.visualiser' | translate }}
{{ 'menu.restitution' | translate }}
{{ 'menu.équipes' | translate }}
{{ 'menu.recherche' | translate }}
{{ 'menu.feedback' | translate }}
{{ 'menu.feedback spontané' | translate }}
{{ 'menu.observer' | translate }}
{{ 'menu.progresser' | translate }}
{{ 'menu.support' | translate }}
{{ 'menu.contact' | translate }}
{{ 'menu.Politique de confidentialité' | translate }}
{{ 'menu.echanger' | translate }}
{{ 'menu.campagne 360' | translate }}
{{ 'menu.pdi' | translate }}
{{ 'menu.certification' | translate }}
{{ 'menu.formation' | translate }}
{{ 'menu.equipes KPI' | translate }}
{{ 'menu.dashboard équipe' | translate }}
{{ 'menu.Évenement' | translate }}
{{ 'menu.specific.skillsup.Mes coachings' | translate }}
{{ 'menu.join' | translate }}

{{ 'ema.share.results.Jamais' | translate }}
<!--a cause d'un bug dans ngx-translate-extract qui ignore les trucs dans des ternaires ? (voir ema-share-objectives.component.html) -->

{{ 'global.Choisir' | translate }}
<!-- utilisé dans ema-share-skill-add-ogc : hiji-criteria-select mais qui est dans le backoffice -->
