<div class="container-fluid bg-light-30p h-100" style="overflow-y: hidden">
  <i class="fa-light fa-spinner spinning" *ngIf="loading"></i>
  <ng-container *ngIf="!loading">
    <!--<div class="row mt-3" *ngIf="getNbActions() > 0">
      <div class="col text-center" *ngIf="getNbActions() === 1">
        {{ 'home.Bienvenue, vous avez 1 action en attente' | translate }}
      </div>
      <div class="col text-center" *ngIf="getNbActions() > 1">
        {{ 'home.Bienvenue, vous avez \{\{nb\}\} actions en attente' | translate : { nb: getNbActions() } }}
      </div>
    </div>-->
    <div
      class="row justify-content-center mt-3"
      *ngIf="envService.configuration.warningHomeMessage !== null && envService.configuration.warningHomeMessage !== undefined"
    >
      <div class="col-auto">
        <div class="bg-warning text-white p-2 my-fs-2">{{ envService.configuration.warningHomeMessage }}</div>
      </div>
    </div>
    <div class="row" style="overflow-x: hidden">
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp">
        <a class="text-body" routerLink="/user/{{ data.me.idUser }}/{{ string_to_slug(data.me.firstName + '-' + data.me.lastName) }}">
          <hiji-user-card [user]="data.me" [showCallToAction]="true"></hiji-user-card>
        </a>
      </div>

      <!-- afficher les invitation des managers -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let managerInvitation of data.managerInvitations">
        <hiji-manager-invitation-card
          [data]="managerInvitation"
          (remove)="data.managerInvitations.splice(data.managerInvitations.indexOf(managerInvitation), 1)"
        ></hiji-manager-invitation-card>
      </div>

      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let feedback of data.feedbacks">
        <hiji-feedback-card [feedback]="feedback"></hiji-feedback-card>
      </div>

      <!-- enquêtes à répondre -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let survey of data.surveys">
        <hiji-survey-card [survey]="survey"></hiji-survey-card>
      </div>

      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let session of data.certificationSessionLiteDTO">
        <hiji-certification-session-card [certificationSessionLiteDTO]="session"></hiji-certification-session-card>
      </div>

      <ng-container *ngIf="modulesDTO.moduleTeamBoard">
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="data.team.length > 0 && iDontHaveATeamBoard()">
          <hiji-teamboard-create-card></hiji-teamboard-create-card>
        </div>
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let teamboard of data.teamBoards">
          <hiji-teamboard-card [data]="data" [teamboard]="teamboard"></hiji-teamboard-card>
        </div>
      </ng-container>

      <ng-container *ngIf="modulesDTO.moduleMesureImpactFormation">
        <!-- Les evenements d'un participant -->
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let event of data.formationEvents | orderBy: 'startDate'">
          <hiji-mif-event-card [event]="event"></hiji-mif-event-card>
        </div>

        <!-- Les sessions de formation d'un formateur ou d'un participant -->
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let session of data.formationSessions | orderBy: 'minDate'">
          <hiji-mif-formation-session-card [formationSession]="session"></hiji-mif-formation-session-card>
        </div>

        <!-- Les attentes d'un manager pour une formation d'un de ses collab -->
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let expectation of data.expectations">
          <hiji-mif-manager-expectations-card [expectation]="expectation"></hiji-mif-manager-expectations-card>
        </div>

        <!-- L'EVA à rédiger par un manager pour un participant de formation -->
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let eva of data.evas">
          <hiji-mif-eva-card [eva]="eva"></hiji-mif-eva-card>
        </div>

        <!-- Evaluation de son plan d'action -->
        <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let action of data.actions">
          <hiji-mif-participant-evaluation-actions-card [action]="action"></hiji-mif-participant-evaluation-actions-card>
        </div>
      </ng-container>

      <!-- afficher les campagnes 360 à créer -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let inquiryToCreate of data.inquiryToCreate">
        <hiji-inquiry360-create-card [inquiryToCreate]="inquiryToCreate"></hiji-inquiry360-create-card>
      </div>

      <!-- afficher les campagnes 360 à répondre -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let inquiryToAnswer of data.inquiryToAnswer">
        <hiji-inquiry360-answer-card [inquiryToAnswer]="inquiryToAnswer"></hiji-inquiry360-answer-card>
      </div>

      <!-- Les sollicitations de feedback -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let solicitation of data.feedbackSolicitations">
        <hiji-fbs-solicitation-card [solicitation]="solicitation"></hiji-fbs-solicitation-card>
      </div>

      <!-- son équipe -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let member of getTeam(true)">
        <a class="text-body" routerLink="/user/{{ member.idUser }}/{{ string_to_slug(member.firstName + '-' + member.lastName) }}">
          <hiji-user-card [user]="member" [showCallToAction]="true"></hiji-user-card>
        </a>
      </div>

      <!-- Les réactions à son feedback -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let reaction of data.feedbackReactions">
        <hiji-fbs-reaction-card [reaction]="reaction"></hiji-fbs-reaction-card>
      </div>

      <!-- certification rejoindre une session -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="modulesDTO.moduleCertification">
        <hiji-rejoindre-certification-card></hiji-rejoindre-certification-card>
      </div>

      <!-- encourager à envoyer / solliciter du FBS -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="modulesDTO.moduleFBS && modulesDTO.displayFBSHomeButton">
        <a class="text-body" routerLink="/search/send-fbs">
          <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
            <div class="row g-1 px-2 py-3 flex-grow-1">
              <div class="col-auto d-flex align-items-center">
                <i class="fa-light fa-gift fa-2x text-tertiary align-icon"></i>
              </div>
              <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-auto">
                    <b>{{ 'fbs.menu-card.Envoyer du feedback spontané' | translate }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="modulesDTO.moduleFBS && modulesDTO.displayFBSHomeButton">
        <a class="text-body" routerLink="/search/sollicitation-fbs">
          <div class="bg-white bg-light-30p-hover border rounded-3 d-flex h-100">
            <div class="row g-1 px-2 py-3 flex-grow-1">
              <div class="col-auto d-flex align-items-center">
                <i class="fa-light fa-hand-sparkles fa-2x text-tertiary align-icon"></i>
              </div>
              <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-auto">
                    <b>{{ 'fbs.menu-card.Solliciter du feedback spontané' | translate }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>

      <!-- encourager à mettre une photo de profil -->
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="encouragePicture">
        <hiji-encourage-picture-card></hiji-encourage-picture-card>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="data.hasGroups && !modulesDTO.hideHomeTeamButtons && modulesDTO.moduleFBS">
        <hiji-groups-card></hiji-groups-card>
      </div>
      <div
        class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp"
        *ngIf="!data.hasGroups && !modulesDTO.hideHomeTeamButtons && modulesDTO.moduleFBS"
      >
        <hiji-group-create-card></hiji-group-create-card>
      </div>
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngFor="let member of getTeam(false)">
        <a class="text-body" routerLink="/user/{{ member.idUser }}/{{ string_to_slug(member.firstName + '-' + member.lastName) }}">
          <hiji-user-card [user]="member" [showCallToAction]="true"></hiji-user-card>
        </a>
      </div>
      <div class="col-12 col-lg-6 mt-3 animate__animated animate__bounceInUp" *ngIf="!modulesDTO.hideHomeTeamButtons">
        <hiji-search-card></hiji-search-card>
      </div>
    </div>

    <div class="row">
      <div
        class="col-12 p-2 my-fs-2 text-center animate__animated animate__bounceInUp"
        *ngIf="!modulesDTO.manualHierarchicalManagement && (modulesDTO.moduleCampaign || modulesDTO.moduleEMA) && envService.configuration.showContactHelp"
      >
        {{ 'team-list.Vous ne trouvez pas un collaborateur ?' | translate }}
        <a class="text-info pointer" routerLink="/contact/missing-colleague">{{ 'team-list.Envoyez-nous un message' | translate }}</a>
      </div>
    </div>
  </ng-container>
</div>
